<template>
    <a-modal :visible="colourModal.visible" :footer="null" :centered="true" @cancel="closeModal" :width="324">
        <div slot="title" style="text-align:center">Colours</div>
        <div class="dF aC">
            <div :class="colorI >= 0 && colorI <=9 ? 'px-1':''" v-for="(color, colorI) in colourList" :key="color + colorI" :value="color" >
                <div class="relative" @click="chooseColor(color)" v-if="colorI >= 0 && colorI <=9" :style="{'background-color':color}" style="border-radius:50%; height:20px; width:20px; cursor:pointer">
                    <div v-if="selectedColor == color" class="absolute" style="width:100%; height:100%; display:flex; align-items:center; justify-content:center"><i class="fe fe-check" /></div>
                </div>
            </div>
        </div>
        <div class="dF aC mt-2">
            <div :class="colorI >= 10 && colorI <=19 ? 'px-1':''" v-for="(color, colorI) in colourList" :key="color + colorI" :value="color" >
                <div class="relative" @click="chooseColor(color)" v-if="colorI >= 10 && colorI <=19" :style="{'background-color':color}" style="border-radius:50%; height:20px; width:20px; cursor:pointer">
                    <div v-if="selectedColor == color" class="absolute" style="width:100%; height:100%; display:flex; align-items:center; justify-content:center"><i class="fe fe-check" /></div>
                </div>
            </div>
        </div>
    </a-modal>
</template>

<script>
export default {
    computed:{
        colourModal() {
            // if (this.$store.state.contacts.colourModal.visible) this.selectedColor = JSON.parse(JSON.stringify(this.$store.state.contacts.colourModal.object.colour))
            return this.$store.state.contacts.colourModal
        },
        visible() {
            return this.$store.state.contacts.colourModal.visible
        }
    },
    watch:{
        visible:{
            handler(val) {
                if (val) {
                    this.selectedColor = this.$store.state.contacts.colourModal.object.colour
                    console.log('selected', this.selectedColor)
                }
            }
        }
    },
    data() {
        return{
            selectedColor:'',
            colourList:[
                '#2C94C8',
                '#3A558A',
                '#FDA28F',
                '#9E69B0',
                '#FEC267',
                '#917B56',
                '#CD4386',
                '#E089B8',
                '#EF6D8B',
                '#FD395D',
                '#227949',
                '#9CB63E',
                '#616161',
                '#18A1B1',
                '#58CAD5',
                '#F3AC46',
                '#FED762',
                '#0A5A93',
                '#83776B',
                '#BEB7AC'
            ],
        }
    },
    methods:{
        closeModal() {
            this.$emit('selectColour', {name:this.$store.state.contacts.colourModal.object.name, colour:this.selectedColor})
            this.$store.commit('CLOSE_COLOUR')
            this.selectedColor = ''
        },
        chooseColor(color) {
            this.selectedColor = color
        }
    }
}
</script>

<style>

</style>